import React from "react"
import { graphql } from "gatsby"

import { areYouARobot } from "../utility"
import { UnlockContactInfo, ContactInfo } from "./contact"
import SEO from "../components/seo"

export default function Privacy({ data }) {
  const [contactInfo, setContactInfo] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  async function unlockContactInfo(e) {
    if (!contactInfo && !loading) {
      setLoading(true)
      const ci = await areYouARobot()
      // too quick :)
      setTimeout(() => {
        setContactInfo(ci)
        setLoading(false)
      }, 1000)
    }
  }

  return (
    <>
      <SEO
        title="Privacy Policy"
        description="An overview of data protection"
        article={false}
        noIndex
      />

      <div className="textTemplateContent">
        <div className="container d-flex justify-content-center">
          <div className="col-12 col-md-8">
            <h1>Privacy Policy</h1>

            <h2>1. An overview of data protection</h2>

            <h3>General information</h3>
            <p>
              The following information will provide you with an easy to
              navigate overview of what will happen with your personal data when
              you visit our website. The term “personal data” comprises all data
              that can be used to personally identify you. For detailed
              information about the subject matter of data protection, please
              consult our Data Protection Declaration, which we have included
              beneath this copy.
            </p>

            <h3>Data recording on our website</h3>
            <h4>
              Who is the responsible party for the recording of data on this
              website (i.e. the “controller”)?
            </h4>
            <p>
              The data on this website is processed by the operator of the
              website, whose contact information is available under section
              “Information Required by Law” on this website.
            </p>

            <h4>How do we record your data?</h4>
            <p>
              We collect your data as a result of your sharing of your data with
              us. This may, for instance be information you enter into our
              contact form.
            </p>
            <p>
              Our IT systems automatically record other data when you visit our
              website. This data comprises primarily technical information (e.g.
              web browser, operating system or time the site was accessed). This
              information is recorded automatically when you access our website.
            </p>

            <h4>What are the purposes we use your data for?</h4>
            <p>
              You have the right to receive information about the source,
              recipients and purposes of your archived personal data at any time
              without having to pay a fee for such disclosures. You also have
              the right to demand that your data are rectified, blocked or
              eradicated. Please do not hesitate to contact us at any time under
              the address disclosed in section “Information Required by Law” on
              this website if you have questions about this or any other data
              protection related issues. You also have the right to log a
              complaint with the competent supervising agency.
            </p>
            <p>
              Moreover, under certain circumstances, you have the right to
              demand the restriction of the processing of your personal data.
              For details, please consult the Data Protection Declaration under
              section “Right to Restriction of Data Processing.”
            </p>

            <h2>2. General information and mandatory information</h2>
            <h3>Data protection</h3>
            <p>
              The operators of this website and its pages take the protection of
              your personal data very seriously. Hence, we handle your personal
              data as confidential information and in compliance with the
              statutory data protection regulations and this Data Protection
              Declaration.
            </p>
            <p>
              Whenever you use this website, a variety of personal information
              will be collected. Personal data comprises data that can be used
              to personally identify you. This Data Protection Declaration
              explains which data we collect as well as the purposes we use this
              data for. It also explains how, and for which purpose the
              information is collected.
            </p>
            <p>
              We herewith advise you that the transmission of data via the
              Internet (i.e. through e-mail communications) may be prone to
              security gaps. It is not possible to completely protect data
              against third party access.
            </p>

            <h3>
              Information about the responsible party (referred to as the
              “controller” in the GDPR)
            </h3>
            <p>The data processing controller on this website is:</p>
            <p>
              Thomas Zipner
              <br />
              Wladhofstr. 3<br />
              68169 Mannheim
              <br />
            </p>
            <p>
              {contactInfo ? (
                <ContactInfo contactInfo={contactInfo} />
              ) : (
                <UnlockContactInfo
                  unlockContactInfo={unlockContactInfo}
                  loading={loading}
                />
              )}
            </p>
            <p>
              The controller is the natural person or legal entity that
              single-handedly or jointly with others makes decisions as to the
              purposes of and resources for the processing of personal data
              (e.g. names, e-mail addresses, etc.).
            </p>

            <h3>Revocation of your consent to the processing of data</h3>
            <p>
              A wide range of data processing transactions are possible only
              subject to your express consent. You can also revoke at any time
              any consent you have already given us. To do so, all you are
              required to do is sent us an informal notification via e-mail.
              This shall be without prejudice to the lawfulness of any data
              collection that occurred prior to your revocation.
            </p>

            <h3>
              Right to object to the collection of data in special cases; right
              to object to direct advertising (Art. 21 GDPR)
            </h3>
            <p>
              In the event that data are processed on the basis of Art. 6 Sect.
              1 lit. e or f GDPR, you have the right to at any time object to
              the processing of your personal data based on grounds arising from
              your unique situation. This also applies to any profiling based on
              these provisions. To determine the legal basis, on which any
              processing of data is based, please consult this Data Protection
              Declaration. If you log an objection, we will no longer process
              your affected personal data, unless we are in a position to
              present compelling protection worthy grounds for the processing of
              your data, that outweigh your interests, rights and freedoms or if
              the purpose of the processing is the claiming, exercising or
              defence of legal entitlements (objection pursuant to Art. 21 Sect.
              1 GDPR).
            </p>

            <p>
              If your personal data is being processed in order to engage in
              direct advertising, you have the right to at any time object to
              the processing of your affected personal data for the purposes of
              such advertising. This also applies to profiling to the extent
              that it is affiliated with such direct advertising. If you object,
              your personal data will subsequently no longer be used for direct
              advertising purposes (objection pursuant to Art. 21 Sect. 2 GDPR).
            </p>

            <h3>
              Right to log a complaint with the competent supervisory agency
            </h3>
            <p>
              In the event of violations of the GDPR, data subjects are entitled
              to log a complaint with a supervisory agency, in particular in the
              member state where they usually maintain their domicile, place of
              work or at the place where the alleged violation occurred. The
              right to log a complaint is in effect regardless of any other
              administrative or court proceedings available as legal recourses.
            </p>

            <h3>Right to data portability</h3>
            <p>
              You have the right to demand that we hand over any data we
              automatically process on the basis of your consent or in order to
              fulfil a contract be handed over to you or a third party in a
              commonly used, machine readable format. If you should demand the
              direct transfer of the data to another controller, this will be
              done only if it is technically feasible.
            </p>

            <h3>SSL and/or TLS encryption</h3>
            <p>
              For security reasons and to protect the transmission of
              confidential content, such as purchase orders or inquiries you
              submit to us as the website operator, this website uses either an
              SSL or a TLS encryption programme. You can recognise an encrypted
              connection by checking whether the address line of the browser
              switches from “http://” to “https://” and also by the appearance
              of the lock icon in the browser line.
            </p>
            <p>
              If the SSL or TLS encryption is activated, data you transmit to us
              cannot be read by third parties.
            </p>

            <h3>
              Information about, blockage, rectification and eradication of data
            </h3>
            <p>
              Within the scope of the applicable statutory provisions, you have
              the right to at any time demand information about your archived
              personal data, their source and recipients as well as the purpose
              of the processing of your data. You may also have a right to have
              your data rectified, blocked or eradicated. If you have questions
              about this subject matter or any other questions about personal
              data, please do not hesitate to contact us at any time at the
              address provided in section “Information Required by Law.”
            </p>

            <h3>Right to demand processing restrictions</h3>
            <p>
              You have the right to demand the imposition of restrictions as far
              as the processing of your personal data is concerned. To do so,
              you may contact us at any time at the address provided in section
              “Information Required by Law.” The right to demand restriction of
              processing applies in the following cases:
            </p>
            <ul>
              <li>
                In the event that you should dispute the correctness of your
                data archived by us, we will usually need some time to verify
                this claim. During the time that this investigation is ongoing,
                you have the right to demand that we restrict the processing of
                your personal data.
              </li>
              <li>
                If the processing of your personal data was/is conducted in an
                unlawful manner, you have the option to demand the restriction
                of the processing of your data in lieu of demanding the
                eradication of this data.
              </li>
              <li>
                If we do not need your personal data any longer and you need it
                to exercise, defend or claim legal entitlements, you have the
                right to demand the restriction of the processing of your
                personal data instead of its eradication.
              </li>
              <li>
                If you have raised an objection pursuant to Art. 21 Sect. 1
                GDPR, your rights and our rights will have to be weighed against
                each other. As long as it has not been determined whose
                interests prevail, you have the right to demand a restriction of
                the processing of your personal data
              </li>
            </ul>
            <p>
              If you have restricted the processing of your personal data, these
              data – with the exception of their archiving – may be processed
              only subject to your consent or to claim, exercise or defend legal
              entitlements or to protect the rights of other natural persons or
              legal entities or for important public interest reasons cited by
              the European Union or a member state of the EU.
            </p>

            <h3>Rejection of unsolicited e-mails</h3>
            <p>
              We herewith object to the use of contact information published in
              conjunction with the mandatory information to be provided in
              section “Information Required by Law” to send us promotional and
              information material that we have not expressly requested. The
              operators of this website and its pages reserve the express right
              to take legal action in the event of the unsolicited sending of
              promotional information, for instance via SPAM messages.
            </p>

            <h2>3. Recording of data on our website</h2>
            <h3>Cookies</h3>
            <p>
              In some instances, our website and its pages use so-called
              cookies. Cookies do not cause any damage to your computer and do
              not contain viruses. The purpose of cookies is to make our website
              more user friendly, effective and more secure. Cookies are small
              text files that are placed on your computer and stored by your
              browser.
            </p>

            <p>
              Most of the cookies we use are so-called “session cookies.” They
              are automatically deleted after your leave our site. Other cookies
              will remain archived on your device until you delete them. These
              cookies enable us to recognise your browser the next time you
              visit our website.
            </p>

            <p>
              You can adjust the settings of your browser to make sure that you
              are notified every time cookies are placed and to enable you to
              accept cookies only in specific cases or to exclude the acceptance
              of cookies for specific situations or in general and to activate
              the automatic deletion of cookies when you close your browser. If
              you deactivate cookies, the functions of this website may be
              limited.
            </p>

            <p>
              Cookies that are required for the performance of the electronic
              communications transaction or to provide certain functions you
              want to use (e.g. the shopping cart function), are stored on the
              basis of Art. 6 Sect. 1 lit. f GDPR. The website operator has a
              legitimate interest in storing cookies to ensure the technically
              error free and optimised provision of the operator’s services. If
              other cookies (e.g. cookies for the analysis of your browsing
              patterns) should be stored, they are addressed separately in this
              Data Protection Declaration.
            </p>

            <h3>Server log files</h3>
            <p>
              The provider of this website and its pages automatically collects
              and stores information in so-called server log files, which your
              browser communicates to us automatically. The information
              comprises:
            </p>
            <ul>
              <li>The type and version of browser used</li>
              <li>The used operating system</li>
              <li>Referrer URL</li>
              <li>The hostname of the accessing computer</li>
              <li>The time of the server inquiry</li>
              <li>The IP address</li>
            </ul>
            <p>This data is not merged with other data sources.</p>
            <p>
              his data is recorded on the basis of Art. 6 Sect. 1 lit. f GDPR.
              The operator of the website has a legitimate interest in the
              technically error free depiction and the optimization of the
              operator’s website. In order to achieve this, server log files
              must be recorded.
            </p>

            <h3>Request by e-mail or telephone</h3>
            <p>
              If you contact us by e-mail or telephone, your request, including
              all resulting personal data (name, request) will be stored and
              processed by us for the purpose of processing your request. We do
              not pass these data on without your consent.
            </p>
            <p>
              The processing of these data is based on Art. 6 para. 1 lit. b
              GDPR, if your request is related to the execution of a contract or
              if it is necessary to carry out pre-contractual measures. In all
              other cases, the processing is based on your consent (Article 6
              (1) a GDPR) and/or on our legitimate interests (Article 6 (1) (f)
              GDPR), since we have a legitimate interest in the effective
              processing of requests addressed to us.
            </p>
            <p>
              The data sent by you to us via contact requests remain with us
              until you request us to delete, revoke your consent to the storage
              or the purpose for the data storage lapses (e.g. after completion
              of your request). Mandatory statutory provisions - in particular
              statutory retention periods - remain unaffected.
            </p>

            <h2>Forwarding of personal data to third parties</h2>
            <h3>Website analysis services</h3>
            <h4>Google Analytics</h4>
            <p>
              This website uses Google Analytics, a web analytics service
              provided by Google Inc. (https://www.google.de/intl/de/about/)
              (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; from
              here on Google). Google Analytics uses cookies, which are text
              files stored on your computer that help the website analyze how
              users use the site. The information generated by the cookie about
              your use of this website including
            </p>
            <ul>
              <li>browser type/version,</li>
              <li>operating system used</li>
              <li>referrer URL (the previously visited page)</li>
              <li>host name of the accessing computer (IP address) and</li>
              <li>time of the server request</li>
            </ul>
            <p>
              is usually transferred to a Google server in the United States and
              stored there. The information is used to evaluate the use of the
              website, compile reports on the website activities, and provide
              further services associated with the use of the website and the
              Internet for the purposes of market research and customized design
              of these Internet pages.
            </p>
            <p>
              This information may also be transferred to third parties if this
              is required by law or if third parties process this data on behalf
              of the company. Under no circumstances will your IP address be
              merged with other data from Google. The IP addresses are
              anonymized so that it is not possible to associate the data with a
              user (IP masking). However, if IP anonymization is activated on
              this website, Google will first shorten your IP address within
              Member States of the European Union and in other states party to
              the Agreement on the European Economic Area. Only in exceptional
              cases will the full IP address be transmitted to a Google server
              in the United States and shortened there. The IP address
              transmitted by your browser for Google Analytics is not merged
              with other Google data. We would like to point out that this
              website uses Google Analytics with the extension “_anonymizeIp ()”
              and therefore IP addresses are only processed in shortened form in
              order to exclude a direct personal association.
            </p>

            <h4>Google Tag Manager</h4>
            <p>
              This website uses the Google Tag Manager of Google Inc. (1600
              Amphitheatre Parkway, Mountain View, CA 94043, United States;
              “Google”). This is used to manage our analysis services described
              above. Google Tag Manager only implements tags. This means that no
              cookies are used, and no personal data is collected. Google Tag
              Manager triggers other tags that may collect data. However, Google
              Tag Manager does not access this data. If a deactivation has been
              made at the domain or cookie level, it remains valid for all
              tracking tags if they are implemented using Google Tag Manager.
            </p>
            <p>
              More information about Google Tag Manager can be found at:
              http://www.google.de/tagmanager/faq.html and
              http://www.google.de/tagmanager/use-policy.html.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "me_annoyed.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 699) {
          ...GatsbyImageSharpFluid_noBase64
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
